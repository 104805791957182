.order-input{
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    border: 1px solid #000;
}

.order-table{
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
    font-size: 14px;
    text-align: center;
}

.order-input-product{
    width: calc(100% - 2px);
    margin-right: 2px;
    height: 30px;
    padding: 0;
}

.order-button{
    width: calc(100% - 2px);
    margin-left: 2px;
    height: 30px;
    padding: 0;
    background-color: white;
    border: none;
}

.order-th1{
    width: 70%;
    height: 30px;
}

.order-th2{
    width: 25%;
    height: 30px;
}

.order-th3{
    min-width: 40px;
    width: 5%;
}
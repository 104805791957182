.Background-image{
  background: url(../public/3.webp);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 650px;
  height: 100vh;
}

.Background-image-black{
  width: 100%;
  height: 100vh;
  min-height: 650px;
  background-color: rgba(0, 0, 0, 0.25);
}

.Background{
  background-color: white;
  width: 100%;
  overflow: auto;
  text-align: center;
  justify-items: center;
  align-items: center;
}

.body-div{
  margin-top: 72px;
  min-height: 1vh;
}

.logo{
  width: 240px;
  height: 52px;
}

.logo-image{
  min-width: 10%;
  height: 58px;
  object-fit: contain;
  position: absolute;
  margin-top: 7px;
  border: 0;
}

.textbox{
  width: 80%;
  margin: auto;
  padding-top: calc(50vh - 236px);
}

.body-text{
  text-align: center;
  margin: 10px 0px;
  color: white;
}

.body-text3{
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  white-space: pre-wrap;
  color: white;
}

.body-text4{
  text-align: center;
  margin: 0;
  white-space: pre-wrap;
  color: white;
}

.body-text5{
  text-align: center;
  margin: 0;
  white-space: pre-wrap;
  color: white;
}

.text-center1{
  text-align: center;
  padding-top: 6px;
}

.text-center{
  text-align: center;
}

.body-text1{
  display: inline-block;
  text-align: left;
  margin: 0;
  padding: 0px 30px;
  padding-left: 40px;
  color: white;
  width: 144px;
  border-right: 2px solid rgba(255, 255, 255,0.8);
}

.body-text2{
  display: inline-block;
  text-align: left;
  margin: 0;
  padding: 0px 30px;
  padding-left: 40px;
  color: white;
  width: 144px;
}

.text-margin{
  margin: 0;
  text-align: center;
}

.no-margin{
  text-align: center;
  margin-top: 4px;
  margin-bottom: 0px;
}

.button-round-120px{
  width: 50vw;
  min-width: 80%;
  max-width: 400px;
  display: block;
  text-align: center;
  margin: auto;
  margin-top: 60px;
  text-decoration: none;
}

.mobile-button-round{
  background-color: rgba(0, 0, 0, 0.3);
  margin: auto;
  width: 80%;
  max-width: 400px;
  height: 80px;
  padding-top: 6px;
  line-height: 30px;
  border-radius: 60px;
  border: 2px solid white;
}

.button-round{
  background-color: rgba(0, 0, 0, 0.3);
  margin: auto;
  width: 400px;
  height: 80px;
  border-radius: 60px;
  border: 2px solid white;
}

.button-text{
  margin: 0;
  padding-top: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
  height: 52px;
  color: #fff;
  text-shadow: 0px 0px 1px black;
}

.body-contents{
  width: 100%;
  min-height: 100vh;
  padding-top: 5vh;
  background-color: white;
}

.contents-div{
  width: 1240px;
  max-width: 95vw;
  min-height: 95vh;
  padding-bottom: 5vh;
  margin: auto;
}

.contents-div-text{
  width: 100%;
  margin: 40px 0;
  text-align: center;
}

.contents-div-text2{
  width: 100%;
  height: 60px;
  margin-top: 80px;
  text-align: center;
}

.contents-payments-div{
  display: block;
  width: 80vw;
  height: 120px;
  margin-top: 0px;
  margin-left: calc(( -1 * (80vw - 1240px)/ 2)  );
  text-align: center;
}

.contents-payment-logo{
  display: inline-block;
  height: 42px;
  margin: 10px 20px;
}

.contents-div1{
  width: 100%;
  max-width: 1240px;
  margin: auto;
}


.contents-image{
  display: block;
  width: 100%;
  padding-top: 0vw;
  margin: auto;
  object-fit: contain;
}

.contents-logo-image{
  display: inline-block;
  width: 120px;
  max-height: 50px;
  margin: 10px;
  object-fit: contain;
}

.contents-text-top{
  margin: 4px 0;
  color: #505a62;
}

.contents-text-title{
  margin: 10px 0;
  color: #090f18;
}

.contents-text{
  color: #090f18;
}

@media screen and (max-width: 520px){
  .contents-text1{
    color: #090f18;
    margin: auto;
    text-align: center;
    margin-top: 8px;
  }
  
  .contents-text2{
    color: #090f18;
    max-width: 320px;
    text-align: center;
    margin: auto;
    margin-top: 8px;
  }

  .contents-text-title1{
    margin: 10px 0;
    color: #090f18;
    margin: auto;
    text-align: center;
    max-width: 300px;
  }

  .contents-text-title2{
    margin: 10px 0;
    color: #090f18;
    margin: auto;
    text-align: center;
    max-width: 300px;
  }

  .contents-div-image{
    margin: 40px 0;
    width: 100%;
  }
}

@media screen and (min-width: 521px){
  .contents-text1{
    color: #090f18;
    text-align: center;
    margin: auto;
    margin-top: 8px;
  }
  
  .contents-text2{
    color: #090f18;
    text-align: center;
    margin: auto;
    margin-top: 8px;
  }

  .contents-text-title1{
    margin: 10px 0;
    color: #090f18;
    margin: auto;
    text-align: center;
  }

  .contents-text-title2{
    margin: 10px 0;
    color: #090f18;
    margin: auto;
    text-align: center;
  }

  .contents-div-image{
    margin: 40px 0;
    width: 100%;
  }
}


@media screen and (max-height: 500px){
  .mobile-textbox{
    max-width: 600px;
    margin: auto;
    padding-top: calc(70px + 50vh - 130px);
    box-sizing: border-box;
  }

  .Carousel{
    max-width: 600px;
    position: relative;
    margin: auto;
  }
  
}

@media screen and (min-height: 500px){
  .mobile-textbox{
    max-width: 600px;
    margin: auto;
    padding-top: calc(50vh - 110px);
    box-sizing: border-box;
  }
  
  .Carousel{
    max-width: 600px;
    position: relative;
    margin: auto;
  }
}

.text-shadow{
  text-shadow: 0px 1px 1px black;
}

.font120{
  font-size: 120px;
}

.font80{
  font-size: 80px;
}

.font60{
  font-size: 60px;
}

.font40{
  font-size: 42px;
}

.font36{
  font-size: 36px;
}

.font30{
  font-size: 30px;
}

.font20{
  font-size: 20px;
}

.font18{
  font-size: 18px;
}

.font16{
  font-size: 16px;
}

.font14{
  font-size: 14px;
}

.font12{
  font-size: 12px;
}

.service-button{
  width: 180px;
  height: 60px;
  background-color: white;
  border: 1px solid #666;
  border-radius: 4px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
}

.title{
  width : 100%;
  margin : auto;
  text-align: left;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.carousel-slider {
  position: static;
  margin: 0;
  overflow: hidden;
}

.carousel {
  position: static;
}

.term{
  height: 300px;
  overflow-y: scroll;
  white-space: pre-wrap;
}

.headerpadding{
  padding-top: 72px;
}

@media screen and (max-width: 750px){

  .font1{
    font-size: 16px;
  }
  
  .font2{
    font-size: 30px;
  }
  
  .font3{
    font-size: 20px;
  }
  
}

@media screen and (min-width: 751px) {
  
  .font1{
    font-size: 20px;
  }
  
  .font2{
    font-size: 60px;
  }
  
  .font3{
    font-size: 30px;
  }


}

.contents1{
  width: 100%;
  height: 400px;
}

.contents2{
  width: 100%;
  height: 400px;
}

::-webkit-scrollbar-button {
  display: block;
}

.Background-margin{
  margin-top: 72px;
  padding-bottom: 40px;
}

.full-page{
  margin-top: 72px;
  height: 100vh;
  width: 100%;
}

.account-image{
  width: 300px;
  height: 120px;
  object-fit: contain;
  display: inline-block;
}

.account-div{
  width: 100%;
  text-align: center;
}

.account-text{
  text-align: left;
  margin: 20px 10px;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  vertical-align: top;
}

.account-title{
  font-size: 24px;
  margin-top: 10px;
}

.account-image1{
  width: 100%;
  max-width: 800px;
  border: 1px solid black;
  border-radius: 10px;
  object-fit: contain;
}

.seller-logo{
  height: 100px;
  width: 100px;
  padding: 0px;
  border: 1px solid black;
  border-radius: 10px;
  margin: 0px 10px;
}
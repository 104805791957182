.trade-contents-div-text{
  width: 100%;
  height: 60px;
  margin-top: 40px;
}

.trade-div{
  width: 100%
}

.trade-ul{
  width: 100%;
  padding: 0;
  list-style: none;
}

.trade-li{
  display: flex;
  width: 100%;
  height: 120px;
  border-top: 1px solid gray;
}

.trade-li-checkbox-div{
  width: 5%;
  max-width: 44px;
  height: 104px;
  padding: 8px;
}

.trade-li-checkbox{
  display: block;
  width: 18px;
  height: 18px;
  margin: auto;
  margin-top: 43px;
}

.trade-li-checkbox-checked{

}

.trade-li-image-div{
  width: 10%;
  max-width: 104px;
  height: 104px;
  padding: 8px;
}

.trade-li-image{
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.trade-li-info-div{
  flex: auto;
  height: 104px;
  padding: 8px;
}

.trade-li-info-div-price{
  line-height: 20px;
  margin-top: 20px;
}

.trade-li-price-div{
  width: 15%;
  height: 104px;
  max-width: 120px;
  padding: 8px;
}

.trade-li-date-div{
  width: 15%;
  height: 104px;
  max-width: 120px;
  padding: 8px;
}

.trade-li-info-strong{
  font-size: 18px;
}

.trade-li-price-buy{
  font-size: 18px;
  color: #c84a31;
}

.trade-li-price-sell{
  font-size: 18px;
  color: #1261c4;
}

.trade-li-info-textbox{
  width: 100%;
  height: 18px;
  margin-top: 32px;
  line-height: 20px;
  text-align: center;
}

.trade-li-time-textbox{
  width: 100%;
  height: 18px;
  margin-top: 43px;
  line-height: 18px;
  text-align: center;
}

.login-div{
    width : 100%;
    margin : auto;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.login-title{
    width : 100%;
    margin : auto;
    margin-top: 4px;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #333;
    line-height: 22px;
}

.login-input{
    width: 98%;
    margin: auto;
    height: 36px;
    border: 1px solid #666;
    border-radius: 4px;
    margin-bottom: 1px;
    font-size: 16px;
}

.login-button{
    width: 100%;
    max-width: 400px;
    height: 60px;
    background-color: white;
    border: 1px solid #666;
    border-radius: 4px;
    margin: auto;
    line-height: 60px;
    font-size: 18px;
}

.login-info-div{
    width : 100%;
    margin : auto;
    text-align: left;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    font-weight: 500;
    font-size: 16px;
    color: black;
    line-height: 22px;
    margin-left: 0px;
}

.login-buttons-div{
    width : 98%;
    text-align: right;
    margin-left: 1%;
    margin-top: 8px;
}

@media screen and (max-height: 350px){
    .login-side-button{
        max-width: 160px;
        height: 48px;
        background-color: white;
        border: none;
        margin-left: 10px;
        line-height: 48px;
        font-size: 12px;
    }
    
  }
  
  @media screen and (min-height: 350px){
    .login-side-button{
        max-width: 180px;
        height: 48px;
        background-color: white;
        border: none;
        margin-left: 10px;
        line-height: 48px;
        font-size: 16px;
    }
  }


.signup-terms{
    width: 95%;
    height: 280px;
    margin: auto;
    text-align: left;
    font-size: 18px;
    border: 1px solid black;
    overflow: scroll;
    overflow-x: auto;
}

.signup-checkbox{
    width: 28px;
    height: 28px;
    margin: auto;
    margin-left: 4px;
    display: inline-block;
    vertical-align: middle;
}

.signup-checkbox-div{
    width: 100%;
    min-height: 40px;
    margin: auto;
    margin-top: 10px;
    text-align: left;
    border-bottom: 1px solid black;
    line-height: 30px;
    font-size: 16px;
}

.signup-checkbox-input-div{
    width: 36px;
    height: 100%;
    display: inline-block;
}

.signup-checkbox-text{
    width: calc(100% - 96px);
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
}

.signup-checkbox-text-2{
    width: calc(100% - 36px);
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
}


.signup-checkbox-term{
    width: 60px;
    line-height: 30px;
    display: inline-block;
    text-align: right;
    vertical-align: middle;
    font-size: 14px;
    text-decoration: underline;
}

.checkbox-all{
    margin-top: 40px;
}

.button-margin{
    margin-top: 20px;
}

.margin-2{
    margin-top: 2px;
}

.flex{
    display: flex;
}

.phone-input{
    width: 98%;
    height: 36px;
    border: 1px solid #666;
    border-radius: 4px;
    font-size: 16px;
    display: inline-block;
}

.code-input2{
    width: 100%;
    height: 36px;
    border: 1px solid #666;
    border-radius: 4px;
    margin-top: 2px;
    font-size: 16px;
    display: inline-block;
}

.phone-button{
    width: 78px;
    height: 40px;
    background-color: white;
    border: 1px solid #666;
    border-radius: 4px;
    line-height: 40px;
    font-size: 14px;
    margin-left: 2px;
}

.code-input{
    width: 100%;
    height: 60px;
    border: none;
    border-bottom: 1px solid #666;
    border-radius: 4px;
    font-size: 24px;
    display: inline-block;
}
.Header-top-menu-icon{
    display: inline-block;
    text-decoration: none;
    margin-top: 19px;
    color: white;
    font-size: 24px;
}

.Header-menu-icon{
    display: inline-block;
   text-decoration: none;
   margin-top: 19px;
   color: black;
   font-size: 24px;
}

.Header-top{
    position: fixed;
    height: 72px;
    width: 100vw;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(255,255,255,0.2);
  }
  
  .Header-top-main{
    display: flex;
    width: 90%;
    height: 100%;
    margin: auto;
  }
  
  @keyframes MoveUpDown {
    0%{
         background-color:rgba(255, 255, 255, 0);
    }
    100% {
         background-color:rgba(255, 255, 255, 1);
    }
  }
  
  .Header{
    animation: MoveUpDown 0.08s linear;
    position: fixed;
    z-index: 100;
    height: 72px;
    width: 100%;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  
  .Header-main{
    display: flex;
    width: 90%;
    height: 100%;
    margin: auto;
  }
  
  .Header-ul{
    flex: auto;
    height: 100%;
    margin: 0;
    padding-left: 20%;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .Header-user-ul{
    width: 20%;
    top: 40%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;
  }
  
  .Header-li{
    height: 100%;
    min-width: 80px;
    text-align: center;
    padding: 0px 20px;
  }
  
  .Header-li:hover{
    background-color:rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid black;
  }

  .Header-mobile-li{
    height: 100%;
    width: 15vw;
    max-width: 60px;
    text-align: center;
    padding: 0px 20px;
  }
  
  .Header-mobile-li:hover{
    background-color:rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid black;
  }
  
  .Header-top-menu-link{
    display: inline-block;
    text-decoration: none;
    margin-top: 22px;
    color: white;
    font-size: 18px;
  }
  
  .Header-menu-link{
    display: inline-block;
   text-decoration: none;
   margin-top: 22px;
   color: black;
   font-size: 18px;
  }

.Header-logo-center{
    flex: auto;
    height: 100%;
    margin: 0;
    padding-left: 20%;
    list-style: none;
    display: absolute;
    justify-content: center;
}

.Header-menu-top{
    width: 100vw;
    height: calc(100vh - 72px);
    position: fixed;
    z-index: 5;
    padding: 0;
    margin-top: 72px;
    background-color: white;
    overflow-x: hidden;
    overflow-y: scroll;
    
}

.Header-menu-div{
    position: sticky;
    border-bottom: 1px solid black;
    width: 100%;
    height: 64px;
    font-size: 24px;
    padding: 22px 8px;
    line-height: 20px;
    background-color: white;
    box-sizing: border-box;
}

.Header-menu-text{
    font-size: 24px;
}

.Header-menu-space{
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 100%;
    min-height: calc(60vh - 502px);
    background-color: white;
}

.Header-menu-footer{
    width: 100%;
    height: 80px;
    position: fixed;
    left: 0;
    bottom: 0;
    border-top: 1px solid black;
    font-size: 24px;
    padding: 30px 8px;
    line-height: 20px;
    background-color: white;
    box-sizing: border-box;
}


.Header-mobile-main{
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
}

a{
    text-decoration: none;
    color: black;
}

.header-user-flex{
    text-align: left;
    height: 40px;
    width: 100px;
    font-size: 18px;
}

.header-username{
    width: 98%;
    min-height: 36px;
    line-height: 30px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    margin: 0;
}

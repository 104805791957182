.Coupon-background{
    width: calc(100vw - 2px);
    max-width: 1240px;
    min-height: 100vh;
    padding-top: 10px;
    padding-bottom: 40px;
    margin: auto;
    margin-top: 72px;
    background-color: white;
}

.Coupon-div{
    width: 95%;
    min-height: 120px;
    margin: auto;
    margin-top: 10px;
    padding: 0px 20px;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 0 4px 4px -4px rgba(0,0,0,0.2);
    border-radius: 4px;
}

.Coupon-contents-title{
    width: 100%;
    padding: 14px 0px;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    color: black;
}

.Coupon-contents-text{
    width: 100%;
    box-sizing: border-box;
    font-size: 20px;
    line-height: 20px;
    color: black;
}

.Coupon-contents-date{
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #666;
}

.Coupon-contents-expired{
    width: 100%;
    box-sizing: border-box;
    margin-top: 14px;
    margin-left: 6px;
    font-size: 14px;
    line-height: 20px;
    color: #666;
}

.couponcode-div{
    width : 98%;
    margin : auto;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

.couponcode-input{
    width: calc(100% - 86px);
    height: 36px;
    line-height: 40px;
    border: 1px solid #666;
    border-radius: 4px;
    font-size: 16px;
}

.couponcode-button{
    width: 80px;
    height: 39px;
    background-color: white;
    margin-left: 4px;
    border: 1px solid #666;
    border-radius: 4px;
    line-height: 36px;
    font-size: 16px;
}

.message-div{
    width : 98%;
    margin-left: 1%;
    margin-top: 10px;
    text-align: left;
    justify-content: center;
    align-items: center;
}
.Billing-background{
    width: 100vw;
    min-height: 100vh;
    padding-top: 10px;
    margin-top: 72px;
    background-color: white;
}

.Billing-div{
    width: 95%;
    min-height: 80px;
    margin: auto;
    margin-top: 10px;
    padding: 5px 20px;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 0 4px 4px -4px rgba(0,0,0,0.2);
    border-radius: 4px;
}

.width-98{
    width: 98%;
    margin: auto;
}

.Billing-payments-div{
    width: 100%;
    height: 70px;
    margin: auto;
    margin-top: 10px;
    padding: 0px 10px;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 0 4px 4px -4px rgba(0,0,0,0.2);
    border-radius: 4px;
}

@keyframes ShowBilling {
    0%{
        transform: scaleY(0);
        transform-origin: top;
    }
    50%{
        transform: scaleY(0.25);
        transform-origin: top;
    }
    75% {
        transform: scaleY(0.5);
        transform-origin: top;
    }
    100% {
        transform: scaleY(1);
        transform-origin: top;
    }
  }
  
.Billing-card-div{
    animation: ShowBilling 0.2s linear;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.2);
    border-top: none;
    box-shadow: 0 4px 4px -4px rgba(0,0,0,0.2);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.Billing-card{
    width: 100%;
    padding: 0px 6px;
    box-sizing: border-box;
    background-color: white;
    border-top: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
    display: flex;
}

.Billing-payments-card{
    width: 100%;
    height: 70px;
    padding: 0px 15px;
    box-sizing: border-box;
    background-color: white;
    border-top: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
}

.Billing-contents-title{
    width: 100%;
    min-height: 18px;
    padding: 4px 0px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: black;
}

.Billing-contents-text{
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 20px;
    color: black;
}

.Billing-contents-address{
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    color: #666;
}

.Billing-contents-status{
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    color: black;
}

.Billing-title{
    width : 95%;
    margin : auto;
    text-align: left;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    margin-bottom: 20px;
  }

.Billing-add{
    width : 95%;
    margin : auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    font-size: 16px;
    color: #666;
}

.Billing-button{
    width: 100%;
    height: 60px;
    background-color: white;
    border: 1px solid #666;
    border-radius: 4px;
    line-height: 60px;
    font-size: 18px;
}

.Billing-button-div{
    width: 95%;
    max-width: 800px;
    min-height: 80px;
    margin: auto;
    margin-top: 10px;
    padding: 0px 20px;
    box-sizing: border-box;
    text-align: center;
}

.Billing-payments-title{
    width: 100%;
    padding: 23px 0px;
    box-sizing: border-box;
    font-size: 20px;
    line-height: 24px;
    color: black;
}

.Billing-contents-date{
    width: 100%;
    box-sizing: border-box;
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #666;
}

.Billing-input{
    width: 99%;
    height: 80px;
    border: none;
    background-color: white;
    border: 1px solid #666;
    border-radius: 4px;
    text-align: start;
    line-height: 20px;
    font-size: 16px;
}

.Billing-div-user{
    width: 100%;
    min-height: 60px;
    margin: auto;
    margin-top: 4px;
    padding-left: 6px;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 0 4px 4px -4px rgba(0,0,0,0.2);
    border-radius: 4px;
}

.Billing-div-add{
    width: 100%;
    min-height: 60px;
    margin: auto;
    padding-left: 6px;
    box-sizing: border-box;
    background-color: white;
}

.Billing-card-div-user{
    animation: ShowBilling 0.2s linear;
    width: 100%;
    min-height: 80px;
    margin: auto;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.2);
    box-shadow: 0 4px 4px -4px rgba(0,0,0,0.2);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.Billing-card-user{
    width: 100%;
    min-height: 80px;
    padding: 6px 10px 10px 10px;
    box-sizing: border-box;
    background-color: white;
    border-top: 1px solid rgba(0,0,0,0.2);
    border-radius: 4px;
}

.Billing-title-user{
    margin-top: 4px;
}

.Billing-input-user{
    width: 100%;
    height: 36px;
    border: 1px solid #666;
    border-radius: 4px;
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 16px;
}

.Billing-input-user-half{
    width: calc(50% - 10px);
    height: 36px;
    border: 1px solid #666;
    border-radius: 4px;
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 4px;
    font-size: 16px;
}

.Billing-contents-title-user{
    width: calc(100% - 50px);
    padding: 4px 0px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: black;
    display: inline-block;
}

.Billing-contents-delete-user{
    width: 20px;
    padding: 0px 0px 0px 10px;
    height: 100%;
    font-size: 24px;
    display: inline-block;
}

.Billing-contents-div-text-user{
    width: calc(100% - 40px);
    box-sizing: border-box;
    font-size: 16px;
    line-height: 20px;
    color: black;
    display: inline-block;
}

.Bill1{
    width: 100%;
    height: 60px;
    font-size: 20px;
    padding: 20px 0;
    border-top: 1px solid black;
    text-align: center;
}

.Bill2{
    width: 100%;
    padding: 6px 0;
    font-size: 18px;
}

.Bill3{
    width: 100%;
    padding-bottom: 10px;
    font-size: 16px;
}

.Success-title{
    margin: auto;
    margin-top: 30px;
    text-align: center;
    font-size: 54px;
    font-weight: bold;
}

.Success-text{
    margin: auto;
    text-align: center;
    font-size: 18px;
}

.Success-button-div{
    width: 100%;
    margin: auto;
    margin-top: 60px;
    text-align: center;
}

.Success-button{
    width: 60%;
    max-width: 400px;
    height: 60px;
    background-color: white;
    border: 1px solid #666;
    border-radius: 4px;
    font-size: 18px;
}

.Billing-button1{
    display: inline-block;
    width: 50%;
    height: 60px;
    background-color: white;
    border: 1px solid #666;
    border-radius: 4px;
    line-height: 60px;
    font-size: 18px;
}

.Billing-term{
    width: 100%;
    min-height: 40px;
    margin: auto;
    margin-top: 10px;
    text-align: left;
    line-height: 30px;
    font-size: 18px;
}

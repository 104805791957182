
.user-contents-div{
    width: 1240px;
    max-width: 100vw;
    min-height: 100vh;
    margin: auto;
}

.user-title-div{
    width: 100%;
    height: 65px;
    border-bottom: 1px solid black;
    font-size: 24px;
    line-height: 24px;
    padding: 20px 0;
    text-align: left;
    box-sizing: border-box;
    text-decoration: none;
}

.user-logout{
    font-size: 14px;
}

.user-title-div-profile{
    width: 100%;
    height: 160px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: flex;
}

.user-title-div-profile-icon{
    width: 100px;
    height: 100px;
    margin: 30px;
    border: 2px solid black;
    border-radius: 50px;
}

.user-title-div-profile-text{
    width: calc(100vw - 160px);
    margin-top: 20px;
    flex: 1;
    height: 120px;
    font-size: 24px;
    line-height: 40px;
    font-size: 20px;
}

.user-title-div-profile-text-div{
    width: 90%;
    height: 70px;
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    display: flex;
}

.user-flex1{
    max-width: 160px;
    padding-right: 40px;
}

.user-second-div{
    width: 100%;
    height: 280px;
    border-bottom: 1px solid black;
}
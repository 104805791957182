.About-contents-div{
  width: 100vw;
  max-width: 1240px;
  padding-bottom: 100px;
  margin: auto;
}

.About-Background-image{
  background: url(../img/cs/1.webp);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 40vh;
  margin-top: 70px;
  margin-bottom: 80px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.About-Background-image-black{
  width: 100%;
  height: 40vh;
  background-color: rgba(0, 0, 0, 0.25);
}

.About-Background{
  background-color: white;
  width: 100%;
  overflow-y: hidden;
}

.About-textbox{
  width: 80%;
  min-height: 140px;
  margin: auto;
  padding-top: calc(20vh - 70px);
}

@media screen and (max-width: 480px){
  .about-text1{
    max-width: 220px;
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
    white-space: pre-wrap;
    color: white;
  }
}

@media screen and (min-width: 481px){
  .about-text1{
    text-align: center;
    margin: auto;
    margin-bottom: 20px;
    white-space: pre-wrap;
    color: white;
  }
}

.About-textarea{
  width: 98%;
  height: 360px;
  background-color: white;
  border: 1px solid #666;
  border-radius: 4px;
  padding: 4px;
  font-size: 18px;
  box-sizing: border-box;
  resize: none;
  margin-left: 1%;
}

.About-textarea-contact{
  width: 98%;
  height: 80px;
  padding: 4px;
  background-color: white;
  border: 1px solid #666;
  border-radius: 4px;
  font-size: 18px;
  resize: none;
  box-sizing: border-box;
  margin-left: 1%;
}

.About-margin-bottom{
  margin-bottom: 40px;
}

@media screen and (max-width: 1240px){
  .about-text{
    font-size: 60px;
  }
}

@media screen and (min-width: 1240px){
  .about-text{
    font-size: 80px;
  }
}
.shipping-body{
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid black;
}

.shipping-select-div{
    width: 80%;
    margin: auto;
}

.shipping-buttons-div{
    display: block;
    width: 100%;
    height: 60px;
}

.shipping-mobile-buttons-div{
    display: inline-block;
    width: 50%;
    height: 100%;
}

.shipping-button{
    width: 20%;
    height: 60px;
    background-color: white;
    border: 1px solid black;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
}

.shipping-button-selected{
    width: 20%;
    height: 60px;
    background-color: black;
    color: white;
    border: 1px solid black;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
}

.shipping-button-under{
    width: 20%;
    height: 60px;
    background-color: white;
    border: 1px solid black;
    border-top: none;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
}

.shipping-button-selected-under{
    width: 20%;
    height: 60px;
    background-color: black;
    color: white;
    border: 1px solid black;
    border-top: none;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
}

.shipping-rate{
    display: flex;
}

.shipping-input{
    width: calc(100% - 320px);
    height: 64px;
    padding: 10px;
    box-sizing: border-box;
    text-align: right;
    font-size: 1.5rem;
    outline: none;
    border: 1px solid black;
    border-right: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.shipping-button-align-center{
    width: 80%;
    margin: auto;
    padding-top: 48px;
    text-align: center;
}

.shipping-price-div{
    width: 100%;
}

.shipping-list{
    width: 100%;
    min-height: 95vh;
    margin: auto;
}

.shipping-table{
    width: 100%;
    border-collapse: collapse;
}

.shipping-tr{
    height: 60px;
    border-bottom: 1px solid black;
}

.shipping-td-1{
    width: calc(70% - 160px);
    padding: 10px 0px;
}

.shipping-td-2{
    width: 30%;
    padding: 10px 10px;
    text-align: right;
}

.shipping-td-3{
    width: 5%;
    max-width: 160px;
    padding: 10px 0px;
    text-align: right;
}

.shipping-td-button{
    width: 20%;
    max-width: 160px;
    height: 60px;
    padding: 0;
}

.shipping-buy-button{
    min-width: 100px;
    width: 100%;
    max-width: 160px;
    height: 52px;
    background-color: white;
    border: 1px solid #666;
    border-radius: 4px;
    line-height: 48px;
    text-align: center;
    font-size: 1rem;
    margin-top: 4px;
}

.shipping-mobile-input{
    width: 100%;
    height: 63px;
    padding: 10px;
    box-sizing: border-box;
    text-align: right;
    font-size: 1.5rem;
    outline: none;
    border: 1px solid black;
}

.shipping-mobile-button{
    width: 100%;
    height: 60px;
    background-color: white;
    border: 1px solid black;
    line-height: 60px;
    text-align: center;
}

.shipping-mobile-button-selected{
    width: 100%;
    height: 60px;
    background-color: black;
    color: white;
    border: 1px solid black;
    line-height: 60px;
    text-align: center;
    font-size: 14px;
}

.shipping-mobile-button-under{
    width: 100%;
    height: 60px;
    background-color: white;
    border: 1px solid black;
    border-top: none;
    line-height: 60px;
    text-align: center;
}

.shipping-mobile-button-selected-under{
    width: 100%;
    height: 60px;
    background-color: black;
    color: white;
    border: 1px solid black;
    border-top: none;
    line-height: 60px;
    text-align: center;
}

.shipping-buttons-table{
    width: 100%;
}
.shipping-mobile-buttons-table{
    width: 100%;
    display: flex;
}


.seller-input{
    width: 100%;
    height: 64px;
    padding: 10px;
    box-sizing: border-box;
    text-align: right;
    font-size: 1.5rem;
    outline: none;
    border: 1px solid black;
}

.seller-button{
    width: 20%;
    min-width: 100px;
    max-width: 160px;
    height: 64px;
    background-color: white;
    border: 1px solid black;
    line-height: 60px;
    text-align: center;
    font-size: 16px;
}

.seller-message{
    text-align: center;
}

.footer{
    background-color: #333;
    width: 100%;
    height: 240px;
  }
  
  .footer-main{
    max-width: 1400px;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-logo{
    width: 300px;
    height: 100%;
  }
  
  .footer-logo-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 0;
  }
  
  .footer-contents{
    height: 220px;
    width: 700px;
    margin: 20px 20px;
    margin-bottom: 0px;
    padding-left: 60px;
  }
  
.footer-mobile-contents{
    height: 220px;
    width: 90%;
    margin: 20px 20px;
    margin-bottom: 0px;
}

  .footer-service-link-div{
    width: 100%;
  }
  
  .footer-companyinfo{
    width: 100%;
    margin-top: 100px;
    padding-top: 10px;
    border-top: 1px solid #bbb;
  }

  .footer-mobile-companyinfo{
    width: 100%;
    margin-top: 16px;
    padding-top: 4px;
    border-top: 1px solid #bbb;
    font-size: 14px;
  }
  
  .footer-companyinfo-line{
    color: #bbb;
  }
  
  .footer-service-link-left{
    height: 24px;
    text-decoration: none;
    display: inline-block;
    color: #bbb;
  }
  
  .footer-service-link-left:hover{
    color: white;
  }
  
  .footer-service-link{
    height: 24px;
    text-decoration: none;
    display: inline-block;
    color: #bbb;
    padding-right: 20px;
  }

  .footer-mobile-service-link{
    font-size: 14px;
    height: 18px;
    text-decoration: none;
    display: inline-block;
    color: #bbb;
    padding-right: 20px;
  }
  
  .footer-service-link:hover{
    color: white;
  }

  .footer-text{
    display: inline-block;
  }